.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.special_title {
  color: #00cdb9;
  font-size: 13px;
  font-weight: 600;
}
.special_title {
  color: #00cdb9;
  font-size: 13px;
  font-weight: 600;
}
.sub_category_list_style {
  background-color: #fafafa;
  border: 1px solid #00cdb9;
}
.card_service{
}

.image-show-box{
  width: 100px;
  height: 100px;
  background-color: #00cdb9;
  border-radius: 10px;
  display: flex;
  align-items: start;
  justify-content: center;
}
.image-show-box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sub-services-container {
  padding: 1rem 0;
}

.sub-service-card {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.3s ease;
}

.sub-service-card.selected {
  background: #e8f4ff;
  border-color: #0d6efd;
  box-shadow: 0 2px 4px rgba(13, 110, 253, 0.1);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-label {
  font-weight: 500;
  color: #495057;
  margin-bottom: 0;
}

.price-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0.5rem;
}
.price-input{
  padding-left: 25px !important;
}

.currency-symbol {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.price-input {
  padding-left: 25px;
  border-radius: 6px;
}

.price-input:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.price-input:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.image-show-box{
  width: 100%;
  height: 200px;
  background-color: #ededed;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.image-show-box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.image-show-box button{
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  background-color: #00cdb95d;
  color: #000000;
  border: none;
  padding: 5px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.error{
  color: red;
  font-size: 12px;
  margin-top: 5px;
}


.image-preview-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background: #fff;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.preview-title {
  font-weight: 500;
  color: #333;
}

.image-container {
  width: 100%;
  height: 200px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-delete {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
.business-photos-container {
  padding: 10px;
}

.service-photo-section {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  transition: transform 0.2s ease;
}

.service-photo-section:hover {
  transform: translateY(-2px);
}

.service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.service-title {
  display: flex;
  flex-direction: column;
}

.service-title h5 {
  margin: 0;
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

.service-count {
  color: #666;
  font-size: 14px;
  margin-top: 4px;
}

.view-all-btn {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  padding: 8px 16px;
  border-radius: 20px;
  color: #495057;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.view-all-btn:hover {
  background: #e9ecef;
  color: #212529;
}

.service-images-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.service-image-card {
  position: relative;
  cursor: pointer;
}

.image-wrapper {
  position: relative;
  padding-bottom: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: #f8f9fa;
}

.image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-wrapper:hover img {
  transform: scale(1.05);
}

.more-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  font-weight: 600;
  border-radius: 8px;
}

.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  padding: 20px;
}

.gallery-modal-content {
  background: white;
  width: 95%;
  max-width: 1200px;
  max-height: 90vh;
  border-radius: 12px;
  overflow: hidden;
  animation: modalFade 0.3s ease;
}

.gallery-modal-header {
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  display: flex;
  flex-direction: column;
}

.modal-title h4 {
  margin: 0;
  color: #333;
  font-size: 20px;
}

.modal-title span {
  color: #666;
  font-size: 14px;
  margin-top: 4px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #666;
  cursor: pointer;
  padding: 5px;
  transition: color 0.2s ease;
}

.close-btn:hover {
  color: #333;
}
.modal-body{
}
.gallery-modal-body {
  padding: 20px;
  overflow-y: auto;
  max-height: calc(90vh - 80px);
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.gallery-image-card {
  cursor: pointer;
}

@keyframes modalFade {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .service-images-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
.dropdown-menu {
  min-width: 220px;
  z-index: 1000;
}

.react-datepicker {
  border: none !important;
  box-shadow: none !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  position: static !important;
  transform: none !important;
}


/* /// / ////  */

.legend-item {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.status-dot.active {
  background-color: #28a745;
}

.status-dot.inactive {
  background-color: #dc3545;
}

.active-row {
  color: rgb(0, 188, 44) !important;
}

.inactive-row {
  color: rgb(233, 0, 0) !important;
}

.cities{
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  padding: 20px 20px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
}

.cities:hover{
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
  transform: translateY(-5px);
  transition: all 0.3s ease;
}

.dashboard-card-header{
  background-image: linear-gradient(to right, #89e3db87, #92e6df8d, #9ae8e2cb, #a3ebe5, #abede8, #b4eff0, #bef1f7, #c9f3fc, #daf5ff, #ebf7ff, #f8faff, #ffffff);
  color: #fff;
  padding: 20px 20px;
  border-radius: 10px;
  font-size: 12px;
}



.nav-item-custom a:hover{
  background-color: #c3fff9b4 !important;
  color: #000 !important;
}

.permissions-container {
  border-radius: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.permission-section {
  background-color: #ffffff;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

/* .permission-section:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

.section-title {
  margin-bottom: 15px;
  color: #2c3e50;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.permission-toggles {
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  align-items: center; */
}

.toggle-group {
  
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.toggle-group:hover {
  background-color: #f8f9fa;
}

.toggle-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0;
  padding: 5px;
  font-size: 14px;
  color: #4a5568;
}

.toggle-label span {
  font-weight: 500;
  font-size: 12px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e2e8f0;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

input:checked + .slider {
  background-color: #3b82f6;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3b82f6;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .permission-toggles {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  
  .toggle-label {
    font-size: 13px;
  }
  
  .permission-section {
    padding: 15px;
  }
}

/* Animation for toggle switch */
.slider:before {
  animation: toggleOff 0.2s ease-in-out;
}

input:checked + .slider:before {
  animation: toggleOn 0.2s ease-in-out;
}

@keyframes toggleOn {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(22px);
  }
  100% {
    transform: translateX(20px);
  }
}

@keyframes toggleOff {
  0% {
    transform: translateX(20px);
  }
  50% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(0);
  }
}

/* Section grouping */
.permissions-group {
  margin-bottom: 30px;
}

.permissions-group-title {
  font-size: 18px;
  color: #1a202c;
  margin-bottom: 15px;
  font-weight: 600;
}

/* Custom scrollbar for permissions container */
.permissions-container {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e0 #f8f9fa;
}

.permissions-container::-webkit-scrollbar {
  width: 6px;
}

.permissions-container::-webkit-scrollbar-track {
  background: #f8f9fa;
}

.permissions-container::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 3px;
}

/* Toggle switch hover effects */
.toggle-switch:hover .slider {
  background-color: #cbd5e0;
}

.toggle-switch:hover input:checked + .slider {
  background-color: #25ebde !important;
}

/* Active state animation */
.permission-section.active {
  border-left: 3px solid #00cdb9 !important;
}

/* Loading state for toggles */
.toggle-switch.loading .slider {
  opacity: 0.7;
  cursor: wait;
}

/* Disabled state */
.toggle-switch.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.toggle-switch.disabled .slider {
  background-color: #e2e8f0;
}